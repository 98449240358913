module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kadet","short_name":"kadet","start_url":"/","display":"standalone","background_color":"#000000","theme_color":"#000000","icon":"src/assets/images/kadet_shaped.png","icons":[{"src":"/static/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/static/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/static/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/static/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/static/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/static/icons/icon-310x310.png","sizes":"310x310","type":"image/png"},{"src":"/static/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cf74b7166c4764748ed35eb976b1c7b1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132828013-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"kadet","accessToken":"MC5ZYmhoanhVQUFESUFvc0RJ.77-9e--_ve-_ve-_vXgQ77-9YU1RJ--_ve-_ve-_ve-_ve-_ve-_vV5rK--_ve-_vXpv77-977-977-9DHrvv71T","promptForAccessToken":true,"apiEndpoint":"https://kadet.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
